.contenier {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card-signup {
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 10px auto;
  max-width: 100%;
  height: 80%;
}

.btn {
  margin-right: 100px;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 18px;
  margin: 20px 0;
}

.form-control {
  margin: 10px 0;
  width: 100%;
}

.input {
  width: 100%;
  margin: 10px 0;
}

.date-picker-input {
  width: 100% !important;
}

.MuiDatePicker-root {
  width: 100% !important;
}

.MuiTextField-root {
  width: 100% !important;
}

.terms-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.terms-checkbox {
  margin-right: 0.5rem;
}

.terms-label {
  font-size: 0.875rem;
}

.next-button,
.back-button {
  margin-top: 1rem;
}

.signup-container {
  max-width: 600px;
  margin: 0 auto;
}
