.card-type {
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 0 auto;
  max-width: 50%;
}

.acesso-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
