.cardrec{    
  border-radius: 15px;
  background: rgba(255,255,255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 20px;
  width: 500px;
  height: 200px;
}

.cardrec1{    
  border-radius: 15px;
  background: rgba(255,255,255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 20px;
  width: 500px;
  height: 300px;
}

.form {
  margin: 20px;
}

.input-field{ 
  border: 2px solid #ddd;
  border-radius: 8px;
  width: 80%;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px; 

}

.contenier-rec{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.contenier-rec1{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}