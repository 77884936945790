.card2 {
  margin-top: 50px;
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 20px;
  height: 600px;
  width: 600px;
}
.logo {
  margin-top: 50px;
}
.links {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column; /* Alinha os links em uma coluna */
  gap: 10px; /* Espaço entre os links */
  align-items: center;
}

.links .link {
  color: #000; /* Cor preta para o texto dos links */
  font-size: 16px; /* Tamanho da fonte dos links */
  cursor: pointer; /* Cursor de ponteiro para indicar que é clicável */
  text-decoration: none; /* Remove o sublinhado padrão dos links */
}

.links .link:hover {
  text-decoration: underline; /* Sublinhado ao passar o mouse */
}

.input-field {
  border: 2px solid #ddd;
  border-radius: 8px;
  width: 300px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px;
}

.input-icon {
  position: absolute;
  right: 10px; /* Espaço entre o ícone e a borda do input */
  top: 50%;
  transform: translateY(-50%);
  color: #333; /* Cor do ícone */
  font-size: 24px; /* Tamanho do ícone */
}

.contenier-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
