h1 {
  color: rgb(249, 250, 250);
}
h2 {
  font-size: 15px; /* Corrigi de size para font-size */
  color: rgb(13, 14, 14);
  margin: 5px 0; /* Reduzir o espaço vertical */
}

.div-app {
  height: 20px;  
  margin-top: 20px;
  margin-bottom: 20px;
}

.div-list{
  display: flex;
  align-content: baseline;
}

.div-emp{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%

}
.box-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  width: 150px; 
  height: 150px; 
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease; 
}

.box-button:hover {
  background-color: #e0e0e0; 
  color: black; 
}

.box-image {
  width: auto; 
  height: auto; 
  margin-bottom: 10px; 
  object-fit: contain;
}

.box-text {
  font-size: 16px;  
}


.card-app {
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: left;
  margin: 20px 0; /* Remove a centralização com auto e alinha à esquerda */
  width: 800px;
  height: 200px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.logo {
  width: 90px; /* Ajuste conforme necessário */
  height: auto;
}

.descricao {
  margin-left: 10px; /* Ajusta o espaço à esquerda da descrição */
}

.app-name {
  margin-top: 3px; /* Reduz o espaço entre a descrição e o nome */
  font-size: 1em; /* Ajusta o tamanho para ficar proporcional */
  font-weight: bold; /* Para destacar o nome */
}
