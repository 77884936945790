.card-reset {    
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    margin: 20px auto;
    width: 400px;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    text-align: left;
    margin: 0; /* Remove margin for better spacing */
  }
  
  .form {
    margin-top: 20px;
  }
  
  .input-group {
    position: relative; /* Ensure correct positioning of adornments */
    margin-bottom: 20px;
  }
  
  .input-group p {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #333;
  }
  
  .input-field { 
    border: 2px solid #ddd;
    border-radius: 8px;
    width: 100%;
    font-size: 16px;
  }
  
  .submit-icon {
    color: #1976d2;
    margin-top: 20px;
  }
  
  .submit-icon:hover {
    background-color: rgba(25, 118, 210, 0.1);
  }
  